<template>
  <v-app>
    <v-row justify="center">
      <v-col cols="10" sm="8" md="6" lg="4" class="my-auto">
        <base-material-card color="primary">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Login</div>
          </template>

          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="login"
          >
            <v-container fluid>
              <v-text-field
                v-model="email"
                name="email"
                :rules="[rules.required]"
                label="E-mail"
              ></v-text-field>

              <v-text-field
                v-model="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min_8]"
                :type="show ? 'text' : 'password'"
                name="password"
                label="Password"
                hint="At least 8 characters"
                @click:append="show = !show"
              ></v-text-field>
              <router-link :to="{ name: 'forgot.password' }">
                {{ $t("forgot_password") }}
              </router-link>

              <v-col class="text-right">
                <v-btn
                  class="mr-4 mt-4"
                  type="submit"
                  @click="validate"
                  color="primary"
                  :disabled="!valid"
                >
                  <!-- <v-btn class="mr-4 mt-4" type="submit" @click="validate" color="#9C27b0" :disabled="!valid"> -->
                  Login
                </v-btn>
              </v-col>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
import Localbase from "localbase";

let db = new Localbase("db");
db.config.debug = false;
export default {
  mixins: [validationRules],
  name: "Login",
  data() {
    return {
      show: false,
      password: "",
      email: "",
      valid: false,
    };
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },

    login() {
      var attributes = {
        email: this.email,
        password: this.password,
      };
      this.$store.dispatch("login", attributes).catch((error) => {
        this.$store.dispatch("alerts/error", error);
      });
      db.collection("products").delete();
    },
  },
};
</script>
